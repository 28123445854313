import React from 'react';

export default function Link({ id }: { id: any }) {
    return (
        <div className="flex flex-1 flex-row-reverse items-baseline justify-end lg:flex-row">
            <div className="flex-grow lg:flex lg:justify-end" />
            <div>
                <a style={{ fontWeight: 'bold', float: 'left' }} className="link" href={`/${id}`}>
                    Se annonsen
                </a>
            </div>
        </div>
    );
}
