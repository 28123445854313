import React, { FC, useMemo, useState } from 'react';
import { ParentSize } from '@visx/responsive';
import { Indicators } from './Indicators';
import { Chart } from './Chart';
import { AreaPicker } from './AreaPicker';
import { sum } from 'd3-array';
import pulseTracking from '@finn-no/pulse-sdk';
import { HistDataPoint, RealEstateSalesData } from '../pricestatistic';
import { Box } from '@fabric-ds/react-box';
import NotFound from './404';
import Link from './Link';

const { trackEvent } = pulseTracking;

function averageSales(sales: HistDataPoint[]) {
    return (
        Math.round(
            sales.reduce((acc, cur) => acc + cur.numberOfSales * cur.sqmPrice, 0) /
                sum(sales, (d) => d.numberOfSales) /
                1000,
        ) * 1000
    );
}

function filterSales(sales: HistDataPoint[]): HistDataPoint[] {
    const numSales = sales.map((s) => s.numberOfSales).reduce((a, b) => a + b, 0);
    if (numSales > 10000) {
        return sales.filter((s) => s.numberOfSales > 5);
    }
    return sales;
}

function getNorwegianPropertyTypePlural(singularNorwegianType: string): string {
    // A mapping object from singular to plural property types
    const propertyTypePlurals: Record<string, string> = {
        Leilighet: 'leiligheter',
        Enebolig: 'eneboliger',
        Rekkehus: 'rekkehus',
        Tomannsbolig: 'tomannsboliger',
        Hybel: 'hybler',
        Hytte: 'hytter',
        Hyttetomt: 'hyttetomter',
        Kontor: 'kontorer',
        Næringstomt: 'næringstomter',
        'Rom i bofellesskap': 'rom i bofellesskap',
        Tomter: 'tomter',
        'Butikk/Handel': 'Butikker/Handel',
        'Produksjon/Industri': 'Produksjons/Industri',
        'Lager/Logistikk': 'Lager/Logistikk',
        Kombinasjonslokaler: 'kombinasjonslokaler',
        'Gårdsbruk/Småbruk': 'gårdsbruk/småbruk',
        'Bygård/Flermannsbolig': 'bygårder/flermannsboliger',
        Kjøpesenter: 'kjøpesentre',
        Verksted: 'verksteder',
        'Garasje/Parkering': 'garasjer/parkeringer',
        'Hotell/Overnatting': 'hoteller/overnatting',
        'Serveringslokale/Kantine': 'serveringslokaler/kantiner',
        'Undervisning/Arrangement': 'undervisnings/arrangementer',
        Prosjekt: 'prosjekter',
        'Annet fritid': 'Annen fritid',
        Andre: 'Andre',
    };

    // Return the plural form if it exists or use the singular as is if it does not
    return propertyTypePlurals[singularNorwegianType] || 'boliger';
}

const SquareMeterText: FC<{
    averageSales: number;
    currentPrice: number;
    area: string;
    numSales: number;
    propertyType: string;
}> = ({ area, averageSales, currentPrice, numSales, propertyType }) => {
    const diff = Math.abs(averageSales - currentPrice);
    const overUnder = averageSales < currentPrice ? 'over' : 'under';

    return (
        <div className="text-16">
            <p>
                Pris per m² ligger <strong>{diff.toLocaleString('nb-no')} kr</strong> {overUnder} snittet for{' '}
                {propertyType} i <strong>{area}</strong>.
            </p>
            <p>
                Grafen viser antall {propertyType} solgt i {area} siste året fordelt på pris per m² - totalt{' '}
                <span className="font-bold">{numSales.toLocaleString('nb-no')}</span> {propertyType}
            </p>
        </div>
    );
};

interface ResponseModel {
    status: number;
    message: 'NOT_FOUND' | 'OK';
    data: PriceStatisticResponse;
}

export interface PriceStatisticResponse {
    sqmPrice: number | null;
    header: string;
    clicks: number;
    mapUrl: string;
    imageMapUrl: string;
    adTitle: string;
    type: string;
    vertical: string;
    realEstateSalesData: RealEstateSalesData[];
}

interface Props {
    adId: number;
    deviceType: 'mobile' | 'tablet' | 'desktop';
    response: ResponseModel;
    showLink: boolean;
}

export function App({ adId, deviceType, response, showLink }: Props) {
    if (response.message === 'NOT_FOUND') return <NotFound />;
    const priceStatistic = response.data;

    const realEstateSalesData = priceStatistic.realEstateSalesData;
    const areaKeys = realEstateSalesData.map((a) => a.locationDetails);
    const [area, setArea] = useState(areaKeys[0]);

    const average = useMemo(
        () => averageSales(realEstateSalesData.find((item) => item.locationDetails === area)?.histData ?? []),
        [area],
    );

    const propertyTypePlural = useMemo(
        () =>
            getNorwegianPropertyTypePlural(
                realEstateSalesData.find((item) => item.locationDetails === area)?.propertyType ?? '',
            ),
        [area],
    );

    const squareMeterPrice = priceStatistic.sqmPrice ?? 0;

    const currentRounded = useMemo(() => Math.round(squareMeterPrice / 1000) * 1000, []);
    const realestateSales = useMemo(
        () => filterSales(realEstateSalesData.find((item) => item.locationDetails === area)?.histData ?? []),
        [area],
    );
    const numSales = realestateSales.map((s) => s.numberOfSales).reduce((a, b) => a + b, 0);
    const trackMapClick = () => {
        trackEvent({
            type: 'Click',
            intent: 'Open',
            name: 'See sold properties',
            object: {
                id: adId.toString(),
                type: 'UIElement',
                elementType: 'MapImage',
                items: [
                    {
                        type: 'ClassifiedAd',
                        id: adId.toString(),
                        name: 'n/a',
                        category: 'n/a',
                    },
                ],
            },
            target: {
                id: priceStatistic.mapUrl,
                type: 'Page',
                pageType: 'map',
            },
            vertical: {
                name: 'realestate',
                subVertical: 'realestate_homes',
            },
        });
    };

    const isMobile = deviceType === 'mobile';

    return (
        <div style={{ textRendering: 'geometricPrecision', maxWidth: '1010px' }} className="flex w-full flex-col">
            <Box className="px-0">
                <h2>Prisstatistikk</h2>
                <>{showLink && <Link id={adId} />}</>
                <p>{priceStatistic.header}</p>
            </Box>
            <Indicators totalClicks={priceStatistic.clicks} squareMeterPrice={squareMeterPrice} />
            {squareMeterPrice === 0 && (
                <span className="u-d1">
                    * Kvadratmeterprisen kan ikke beregnes fordi p-rom og BRA-i mangler for denne annonsen.
                </span>
            )}
            <Box className="px-0">
                <div className="flex flex-col sm:flex-row sm:justify-between">
                    <h3>Prisfordeling</h3>
                    <AreaPicker areas={areaKeys} currentArea={area} setCurrentArea={setArea} />
                </div>
                {realestateSales.length > 0 ? (
                    <SquareMeterText
                        numSales={numSales}
                        averageSales={average}
                        currentPrice={squareMeterPrice}
                        area={area}
                        propertyType={propertyTypePlural}
                    />
                ) : (
                    <></>
                )}
            </Box>
            <Box className="w-full px-0" style={{ height: '350px' }}>
                <ParentSize>
                    {({ width, height }) =>
                        realestateSales.length > 0 ? (
                            <Chart
                                isMobile={isMobile}
                                options={{
                                    height: height <= 0 ? 350 : height,
                                    width: isMobile ? width * 2 : width,
                                    margin: {
                                        top: 20,
                                        bottom: 50,
                                        right: 25,
                                        left: 20,
                                    },
                                }}
                                data={{
                                    area: area,
                                    averageSuggestedPrice: average,
                                    currentSuggestedPrice: currentRounded,
                                    sales: realestateSales,
                                }}
                            />
                        ) : (
                            <div>{`Vi har ingen sammenlignbare salg for denne boligen i ${area}`}</div>
                        )
                    }
                </ParentSize>
            </Box>
            <Box className="px-0 pt-32">
                <p>
                    Kvadratmeterpris tilsvarer prisantydning pluss fellesgjeld per kvadratmeter (p-rom eller BRA-i).
                    Prisene i grafen er avrundet til nærmeste 1000 kr.
                </p>
            </Box>
            <Box className="w-full px-0">
                <h3>Eiendommer solgt i samme område</h3>
                <p>I løpet av de siste 12 månedene</p>
                <a className="h-full w-full" onClick={() => trackMapClick()} href={priceStatistic.mapUrl}>
                    <img
                        className="h-full w-full object-cover"
                        src={priceStatistic.imageMapUrl}
                        alt="Bilde av kartet på finn.no"
                    />
                </a>
            </Box>
        </div>
    );
}
