import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './src/App';
import buffer from 'buffer';

const root = document.getElementById('price-statistics-podlet');
const decode = encodedProps => JSON.parse(buffer.Buffer.from(encodedProps, 'base64').toString('utf8'));

if (root) {
    const props = decode(root.dataset.props || '');
    ReactDOM.hydrate(<App {...props} />, root);
}
