import { Button } from '@fabric-ds/react';
import * as React from 'react';

interface Props {
    areas: string[];
    currentArea: string;
    setCurrentArea: (area: string) => void;
}

export function AreaPicker({ areas, currentArea, setCurrentArea }: Props) {
    return (
        <div className="flex pb-16">
            {areas.reverse().map((a, index) => (
                <Button
                    style={{
                        backgroundColor: currentArea === a ? '#E5E5E5' : 'white',
                    }}
                    small
                    utility
                    className="h-32 w-max"
                    onClick={() => setCurrentArea(a)}
                    key={`toggle-${index}`}
                >
                    {a}
                </Button>
            ))}
        </div>
    );
}
