import React, { FC } from 'react';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { Bar, Line } from '@visx/shape';
import { Text } from '@visx/text';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { formatLocale, FormatLocaleDefinition } from 'd3-format';
import { scaleBand, scaleLinear } from '@visx/scale';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { HistDataPoint } from '../pricestatistic';

const d3Locale: FormatLocaleDefinition = {
    currency: ['kr', ''],
    decimal: ',',
    grouping: [3],
    thousands: ' ',
};

type ChartOptions = {
    height: number;
    width: number;
    margin: {
        top: number;
        bottom: number;
        left: number;
        right: number;
    };
};

interface ChartProps {
    data: {
        area: string;
        currentSuggestedPrice: number;
        averageSuggestedPrice: number;
        sales: HistDataPoint[];
    };
    options: ChartOptions;
    isMobile: boolean;
}

export function Chart({ data, options, isMobile }: ChartProps) {
    const { width, height, margin } = options;
    const xMax = width - (margin.left + margin.right);
    const yMax = height - margin.top - margin.bottom;
    const { tooltipOpen, tooltipTop, tooltipLeft, tooltipData, showTooltip, hideTooltip } = useTooltip<HistDataPoint>();
    const { containerRef, TooltipInPortal } = useTooltipInPortal({
        detectBounds: false,
        scroll: true,
    });

    const { sales, currentSuggestedPrice, averageSuggestedPrice } = data;

    const getPrice = (d: HistDataPoint) => d.sqmPrice;
    const getNumSales = (d: HistDataPoint) => d.numberOfSales;

    const xScale = scaleBand<number>({
        domain: range(Math.min(...sales.map(getPrice)) - 2000, Math.max(...sales.map(getPrice)) + 2000), // Hardkoder 2000 som en quickfix for å unngå overflow av "denne boligen" teksten
        range: [0, xMax],
        round: true,
    });

    const numSales = sales.map(getNumSales);
    const maxYValue = Math.max(...numSales);

    const yScale = scaleLinear<number>({
        domain: [0, maxYValue + maxYValue * 0.5],
        range: [yMax, 0],
        round: true,
    });

    const currentSuggestedPriceYValue = sales.find((s) => s.sqmPrice === currentSuggestedPrice);
    const averageSuggestedPriceYValue = sales.find((s) => s.sqmPrice === averageSuggestedPrice);

    const ariaLabel = (area: string, currentSuggestedPrice: number, averageSuggestedPrice: number) => `
        Histogram som viser prisfordeling for boliger i ${area}. 
        Denne boligen koster ${currentSuggestedPrice} kr per kvadratmeter, mens gjennomsnittet er ${averageSuggestedPrice} kr per kvadratmeter
    `;

    const onHover = (event: any, ps: HistDataPoint) => {
        showTooltip({
            tooltipLeft: (xScale(ps.sqmPrice) ?? 0) - (margin.left + margin.right),
            tooltipTop: 0,
            tooltipData: ps,
        });
    };

    const diff = Math.abs(data.averageSuggestedPrice - data.currentSuggestedPrice);

    const hoverBars = sales.map((d, i) => (
        <rect
            key={`hover-bar-${i}`}
            width={xScale.bandwidth()}
            height={yMax}
            x={xScale(d.sqmPrice)}
            fill="transparent"
            onTouchEnd={hideTooltip}
            onMouseOut={hideTooltip}
            onTouchMove={(event) => onHover(event, d)}
            onMouseMove={(event) => onHover(event, d)}
        />
    ));

    return (
        <div className="flex flex-col">
            <p className="text-14 font-bold text-gray-800">Antall boliger</p>
            <div className="flex">
                <div className={`w-44 overflow-x-hidden`}>
                    <svg height={height}>
                        <Group id="y-axis-group" top={margin.top} left={margin.left}>
                            <AxisLeft
                                stroke="#767676"
                                hideTicks
                                hideAxisLine
                                numTicks={5}
                                scale={yScale}
                                tickLabelProps={() => ({
                                    fontSize: 12,
                                    textAnchor: 'middle',
                                    verticalAnchor: 'middle',
                                    fill: '#767676',
                                })}
                            />
                        </Group>
                    </svg>
                </div>
                <div className={`relative ${isMobile ? 'overflow-x-auto' : ''}`}>
                    <svg
                        role="img"
                        ref={containerRef}
                        height={height}
                        width={width}
                        aria-label={ariaLabel(data.area, data.currentSuggestedPrice, data.averageSuggestedPrice)}
                    >
                        <title>Histogram som viser prisfordeling for boliger i {data.area}</title>
                        <desc>
                            Denne boligen koster {data.currentSuggestedPrice} kr per kvadratmeter, mens gjennomsnittet
                            er {data.averageSuggestedPrice} kr per kvadratmeter
                        </desc>
                        <GridRows
                            strokeDasharray={'3'}
                            top={margin.top}
                            left={margin.left}
                            scale={yScale}
                            numTicks={5}
                            width={xMax}
                            height={yMax}
                        />
                        <Group id="x-axis-group" top={yMax + margin.top} left={margin.left}>
                            <AxisBottom
                                scale={xScale}
                                hideZero
                                stroke="#767676"
                                numTicks={4}
                                tickFormat={formatLocale(d3Locale).format(',.2r')}
                                tickLabelProps={() => ({
                                    fontSize: 12,
                                    textAnchor: 'middle',
                                    fill: '#767676',
                                })}
                            />
                            <Text
                                x={xMax - (margin.left + margin.right + 20)}
                                y="40"
                                fontFamily="Finntype"
                                fill="#767676"
                                fontWeight="bold"
                                fontSize={14}
                            >
                                Pris per m² i kr
                            </Text>
                        </Group>
                        {currentSuggestedPrice && xScale(data.currentSuggestedPrice) && (
                            <Group id="current-price" top={margin.top} left={margin.left}>
                                <Line
                                    from={{
                                        x: (xScale(data.currentSuggestedPrice) ?? 0) + xScale.bandwidth() / 2,
                                        y: 0,
                                    }}
                                    to={{
                                        x: (xScale(data.currentSuggestedPrice) ?? 0) + xScale.bandwidth() / 2,
                                        y: yMax,
                                    }}
                                    stroke="#474445"
                                    opacity={tooltipOpen ? '50%' : '100%'}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                />
                                <Text
                                    dx={
                                        data.currentSuggestedPrice > data.averageSuggestedPrice
                                            ? xScale.bandwidth() / 2 + 5
                                            : -105
                                    }
                                    fontFamily="Finntype"
                                    opacity={tooltipOpen ? '50%' : '100%'}
                                    fill="#474445"
                                    verticalAnchor="start"
                                    x={xScale(data.currentSuggestedPrice)}
                                >
                                    Denne boligen
                                </Text>
                                <Text
                                    dx={
                                        data.currentSuggestedPrice > data.averageSuggestedPrice
                                            ? xScale.bandwidth() / 2 + 5
                                            : -75
                                    }
                                    dy={20}
                                    fontFamily="Finntype"
                                    fontWeight="bold"
                                    opacity={tooltipOpen ? '50%' : '100%'}
                                    fill="#474445"
                                    verticalAnchor="start"
                                    x={xScale(data.currentSuggestedPrice)}
                                >
                                    {`${data.currentSuggestedPrice.toLocaleString('nb-no')} kr`}
                                </Text>
                            </Group>
                        )}
                        {diff > 1000 && (
                            <Group id="average-price" top={margin.top} left={margin.left}>
                                <Line
                                    from={{
                                        x: (xScale(data.averageSuggestedPrice) ?? 0) + xScale.bandwidth() / 2,
                                        y: 0,
                                    }}
                                    to={{
                                        x: (xScale(data.averageSuggestedPrice) ?? 0) + xScale.bandwidth() / 2,
                                        y: yMax,
                                    }}
                                    stroke="#474445"
                                    opacity={tooltipOpen ? '50%' : '100%'}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                    strokeDasharray="5,2"
                                />
                                <Text
                                    dx={
                                        data.averageSuggestedPrice > data.currentSuggestedPrice
                                            ? xScale.bandwidth() / 2 + 5
                                            : -95
                                    }
                                    opacity={tooltipOpen ? '50%' : '100%'}
                                    fontFamily="Finntype"
                                    fill="#474445"
                                    verticalAnchor="start"
                                    x={xScale(data.averageSuggestedPrice)}
                                >
                                    Gjennomsnitt
                                </Text>
                                <Text
                                    dx={
                                        data.averageSuggestedPrice > data.currentSuggestedPrice
                                            ? xScale.bandwidth() / 2 + 5
                                            : -70
                                    }
                                    dy={20}
                                    opacity={tooltipOpen ? '50%' : '100%'}
                                    fontFamily="Finntype"
                                    fontWeight="bold"
                                    fill="#474445"
                                    verticalAnchor="start"
                                    x={xScale(data.averageSuggestedPrice)}
                                >
                                    {`${data.averageSuggestedPrice.toLocaleString('nb-no')} kr`}
                                </Text>
                            </Group>
                        )}
                        {tooltipData && (
                            <Line
                                from={{
                                    x: (xScale(tooltipData.sqmPrice) ?? 0) + margin.left + xScale.bandwidth() / 2,
                                    y: margin.top,
                                }}
                                to={{
                                    x: (xScale(tooltipData.sqmPrice) ?? 0) + margin.left + xScale.bandwidth() / 2,
                                    y: yMax + margin.top,
                                }}
                                stroke="#3291ED"
                                strokeWidth={2}
                                pointerEvents="none"
                                strokeDasharray="5,2"
                            />
                        )}
                        <Group id="bar-group" top={margin.top} left={margin.left}>
                            {sales.map((ps, index) => {
                                const price = getPrice(ps);
                                const hover = getPrice(
                                    tooltipData ?? {
                                        sqmPrice: 0,
                                        numberOfSales: 0,
                                        dataLabel: '',
                                    },
                                );
                                const barWidth = xScale.bandwidth();
                                const barHeight = yMax - (yScale(getNumSales(ps)) ?? 0);
                                const barX = xScale(price);
                                const barY = yMax - barHeight;
                                return (
                                    <React.Fragment key={`bar-${index}`}>
                                        <Bar
                                            x={barX}
                                            y={barY}
                                            width={barWidth}
                                            height={barHeight}
                                            fill={tooltipOpen ? (price === hover ? '#3291ED' : '#ABC6F7') : '#3291ED'}
                                        />
                                    </React.Fragment>
                                );
                            })}
                        </Group>
                        <Group id="hover-bar-group" top={margin.top} left={margin.left}>
                            {hoverBars}
                        </Group>
                        <Group id="line-group" top={margin.top} left={margin.left}>
                            <Line
                                from={{
                                    x: (xScale(data.currentSuggestedPrice) ?? 0) + xScale.bandwidth() / 2,
                                    y: yScale(
                                        getNumSales(
                                            currentSuggestedPriceYValue ?? {
                                                numberOfSales: 0,
                                                sqmPrice: 0,
                                                dataLabel: '0',
                                            },
                                        ),
                                    ),
                                }}
                                to={{
                                    x: (xScale(data.currentSuggestedPrice) ?? 0) + xScale.bandwidth() / 2,
                                    y: yMax,
                                }}
                                stroke="white"
                                opacity={tooltipOpen ? '50%' : '100%'}
                                strokeWidth={2}
                                pointerEvents="none"
                            />
                            <Line
                                from={{
                                    x: (xScale(data.averageSuggestedPrice) ?? 0) + xScale.bandwidth() / 2,
                                    y: yScale(
                                        getNumSales(
                                            averageSuggestedPriceYValue ?? {
                                                numberOfSales: 0,
                                                sqmPrice: 0,
                                                dataLabel: '0',
                                            },
                                        ),
                                    ),
                                }}
                                to={{
                                    x: (xScale(data.averageSuggestedPrice) ?? 0) + xScale.bandwidth() / 2,
                                    y: yMax,
                                }}
                                stroke="white"
                                opacity={tooltipOpen ? '50%' : '100%'}
                                strokeWidth={2}
                                pointerEvents="none"
                                strokeDasharray="5,2"
                            />
                        </Group>
                    </svg>
                </div>
                {tooltipOpen && (
                    <TooltipInPortal
                        unstyled={true}
                        applyPositionStyle={true}
                        key={Math.random()}
                        offsetLeft={xScale.bandwidth() / 2}
                        top={tooltipTop}
                        left={tooltipLeft}
                    >
                        <Tooltip sales={tooltipData} />
                    </TooltipInPortal>
                )}
            </div>
        </div>
    );
}

const Tooltip: FC<{ sales?: HistDataPoint }> = ({ sales }) => {
    if (sales === undefined) return null;
    const salesNum = Number(sales?.sqmPrice ?? 0);
    const salesNumPlusThousand = salesNum + 1000;
    return (
        <div className="text-16 rounded-8 flex flex-col items-start bg-gray-800 p-8 text-white">
            <span>
                <strong>{sales?.numberOfSales ?? 0}</strong> salg
            </span>
            <span className="text-14">
                <strong>
                    {salesNum.toLocaleString('nb-no')} - {salesNumPlusThousand.toLocaleString('nb-no')}
                </strong>{' '}
                kr
            </span>
        </div>
    );
};

const range = (start: number, stop: number, step = 1000) =>
    Array(Math.ceil((stop - start) / step))
        .fill(start)
        .map((x, y) => x + y * step);
