import React from 'react';
import { Box } from '@fabric-ds/react-box';

interface Props {
    totalClicks: number;
    squareMeterPrice: number;
}

export function Indicators({ totalClicks, squareMeterPrice }: Props) {
    const sqmString = squareMeterPrice !== 0 ? `${squareMeterPrice.toLocaleString('nb-no')} kr` : '(*)';
    return (
        <Box className="mb-16 flex flex-col px-0 md:flex-row">
            <Box className="flex flex-auto flex-col items-center md:mr-12" neutral={true}>
                <span className="text-22 md:text-34 font-bold">{totalClicks.toLocaleString('nb-no')} klikk</span>
                <p>på annonsen</p>
            </Box>
            <Box className="mt-16 flex flex-auto flex-col items-center md:mt-0" neutral={true}>
                <span className="text-22 md:text-34 font-bold">{sqmString}</span>

                <p>prisantydning per kvadratmeter</p>
            </Box>
        </Box>
    );
}
