export default function NotFound() {
    return (
        <div className="u-text-center u-mv64">
            <h1>404</h1>
            <p>På FINN finner du alt &hellip; bortsett fra denne siden.</p>
            <p>
                <a href="https://www.finn.no">Gå til forsiden</a>
            </p>
            <img
                className={'flex h-screen items-center justify-center'}
                src="https://www.finn.no/assets/404.jpg"
                alt="Katt som holder en FINN-annonse"
            />
        </div>
    );
}
